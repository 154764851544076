import type { UserData } from 'types/userData'

import { setUserDataCookies } from './utils/cookies/setUserDataCookies'
import {
	setUserDataCleanBrandLocalStorage,
	setUserDataLocalStorage,
} from './utils/localStorage/setUserDataLocalStorage'

export const setUserData = async (userData: UserData): Promise<void> => {
	setUserDataLocalStorage(userData)
	await setUserDataCookies(userData)
}

export const setUserDataCleanBrand = async (
	userData: UserData
): Promise<void> => {
	setUserDataCleanBrandLocalStorage(userData)
	await setUserDataCookies(userData)
}
