import type { UserData } from 'types/userData'
import { USER_DATA_LOCAL_STORAGE_KEY } from 'user-data/constants'
import { setToLocalStorage } from 'utils/webStorage/localStorage'

import { getUserDataLocalStorage } from './getUserDataLocalStorage'

export const setUserDataLocalStorage = ({
	userCountryISO,
	userLanguageISO,
	userBrandID,
	userSubBrandID,
}: UserData): void => {
	const userDataFromLocalStorage = getUserDataLocalStorage()
	setToLocalStorage(USER_DATA_LOCAL_STORAGE_KEY, {
		countryISO:
			userCountryISO?.toLowerCase() ?? userDataFromLocalStorage?.userCountryISO,
		languageISO: userLanguageISO ?? userDataFromLocalStorage?.userLanguageISO,
		brandID: userBrandID ?? userDataFromLocalStorage?.userBrandID,
		subBrandID: userBrandID
			? userSubBrandID
			: userDataFromLocalStorage?.userSubBrandID,
	})
}

export const setUserDataCleanBrandLocalStorage = ({
	userCountryISO,
	userLanguageISO,
}: UserData): void => {
	const userDataFromLocalStorage = getUserDataLocalStorage()
	setToLocalStorage(USER_DATA_LOCAL_STORAGE_KEY, {
		countryISO:
			userCountryISO?.toLowerCase() ?? userDataFromLocalStorage?.userCountryISO,
		languageISO: userLanguageISO ?? userDataFromLocalStorage?.userLanguageISO,
		brandID: null,
		subBrandID: null,
	})
}
