import type { UserData } from 'types/userData'
import { SET_USER_DATA_AKAMAI_ENDPOINT } from 'user-data/constants'
import { env } from 'utils/envManager'

export const setUserDataCookies = async ({
	userCountryISO,
	userLanguageISO,
	userBrandID,
	userSubBrandID,
}: UserData): Promise<void> => {
	const url = new URL(SET_USER_DATA_AKAMAI_ENDPOINT, env.NEXT_PUBLIC_API_DOMAIN)

	if (userCountryISO) {
		url.searchParams.set('countryISO', userCountryISO.toLowerCase())
	}
	if (userLanguageISO) {
		url.searchParams.set('languageISO', userLanguageISO)
	}
	if (userBrandID) {
		url.searchParams.set('brandID', userBrandID)
	}
	if (userBrandID && userSubBrandID) {
		url.searchParams.set('subBrandID', userSubBrandID)
	}

	try {
		await fetch(url.href, { next: { revalidate: 0 }, credentials: 'include' })
	} catch {
		console.error('Error setting user data cookies')
	}
}
