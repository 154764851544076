import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useMasterDataIsReady } from 'master-data/hooks/useMasterDataIsReady/useMasterDataIsReady'
import { useEffect } from 'react'
import { handleUserData } from 'user-data/utils/handleUserData'

import type { UserDataProviderProps } from '../context/UserDataProvider'

export const useHandleUserData = (
	dispatch: (newState: Partial<UserDataProviderProps>) => void
) => {
	const {
		brand,
		subBrand,
		country: { countryISO, languageISO, brands },
	} = useMasterData()

	const { masterDataIsReady } = useMasterDataIsReady()

	useEffect(() => {
		if (masterDataIsReady) {
			handleUserData({
				countryBrands: brands,
				brand,
				subBrand,
				dispatch,
				countryISO,
				languageISO,
			})
		}
	}, [masterDataIsReady, brand, subBrand, countryISO, languageISO])

	return null
}
